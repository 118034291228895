<template>
  <div
	  v-if="$root.currentUser && $root.currentUser.isAdmin()"
	  style="display: flex; flex-direction: column">


    <!-------------------------------------->
    <!-- Loading                          -->
    <!-------------------------------------->
    <v-overlay v-if="loading" absolute opacity="0">
      <pulseLoader :loading="loading"></pulseLoader>
    </v-overlay>

    <!-------------------------------------->
    <!-- Sent Correctly Message           -->
    <!-------------------------------------->
    <v-overlay v-if="sent" absolute opacity="0">
      <v-card width="250" height="230" color="white" class="pa-6 d-flex"
              style="border-top: 6px solid #4CAF50 !important;">
        <div class="d-flex flex-column text-center ma-auto">
          <span class="black--text title" style="max-width: 180px">The email has been sent correctly.</span>
<!--          <v-icon size="60" class="mt-4" color="green">mdi-check</v-icon>-->
        </div>
      </v-card>
    </v-overlay>


    <!-------------------------------------->
    <!-- Toolbar                          -->
    <!-------------------------------------->
    <v-card class="shadow-small py-2 px-10 mb-3">
      <div class="text-center pa-0 d-flex mx-auto">
<!--        <v-icon color="#5A8CF8" class="mr-4" size="40">mdi-email-fast-outline</v-icon>-->
        <h1>Nuthe Email Sender</h1>
      </div>
    </v-card>

    <!-------------------------------------->
    <!-- Send button                      -->
    <!-------------------------------------->
    <v-btn
      @click="sendEmail"
      :disabled="!toEmail || !subjectEmail || !htmlEmail"
      class="primary text-none px-10 mr-4" depressed
      style="position: absolute; right: 0px; top: 20px"
    >
      <span class="title" style="font-size: 17px !important;">Send</span>
<!--      <v-icon size="17" class="ml-2">mdi-send</v-icon>-->
    </v-btn>


    <div class="d-flex mx-auto mb-2">

      <!-------------------------------------->
      <!-- Email details                    -->
      <!-------------------------------------->
      <v-card
        style="display: flex; flex-direction: column; width: 600px"
        class="shadow-small mr-2">
        <div class="d-flex align-center">
          <div class="title px-6 py-5">Email details</div>
          <v-spacer></v-spacer>
        </div>
        <v-divider></v-divider>
        <div class="pr-6 py-3">
          <div class="d-flex align-center">
            <span style="font-size: 17px !important; width: 110px" class="title px-6 py-5">
              Names
              <span v-if="namesEmail && namesEmail.split(',').length">
                ({{namesEmail.split(',').length}})
              </span>
            </span>
            <v-text-field v-model="namesEmail" style="max-height: 40px !important;" outlined dense
                          hide-details></v-text-field>
          </div>
          <div class="d-flex align-center">
            <span style="font-size: 17px !important; width: 110px" class="title px-6 py-5">
              To
             <span v-if="toEmail && toEmail.split(',').length">
                ({{toEmail.split(',').length}})
              </span>
            </span>
            <v-text-field v-model="toEmail" style="max-height: 40px !important;" outlined dense
                          hide-details></v-text-field>
          </div>
          <div class="d-flex align-center">
            <span style="font-size: 17px !important; width: 110px" class="title px-6 py-5">Subject</span>
            <v-text-field v-model="subjectEmail" style="max-height: 40px !important;" outlined dense
                          hide-details></v-text-field>
          </div>
        </div>
      </v-card>

      <!-------------------------------------->
      <!-- Excel contacts                   -->
      <!-------------------------------------->
      <v-card
        v-if="false"
        color="primary" width="300" class="shadow-small justify-center text-center d-flex flex-column">
<!--        <v-icon size="40" dark>mdi-microsoft-excel</v-icon>-->
        <span class="white--text mx-auto tit mt-3" style="max-width: 150px">Drop an Excel list of contacts</span>

<!--        <v-tooltip bottom color="white" content-class='custom-tooltip'>>-->
<!--          <template v-slot:activator="{ on }">-->
<!--            i-->
<!--          </template>-->
<!--          <span-->
<!--            class="black&#45;&#45;text">El llistat de contactes ha d'estar situat <br> en la primera columna del taulell <br> i sense cap capçelera.</span>-->
<!--        </v-tooltip>-->
      </v-card>

    </div>


    <!-------------------------------------->
    <!-- HTML Email                       -->
    <!-------------------------------------->
    <div class="d-flex px-3 pb-1" style="flex: 1">
      <v-card style="flex: 1; min-height: 100%; display: flex; flex-direction: column"
              class="shadow-small mr-2 rounded">
        <div class="title px-6 py-5">HTML</div>
        <v-divider></v-divider>
        <v-textarea
          v-model="htmlEmail"
          width="100%"
          height="100%"
          class="rounded-b code"
          style="flex: 1"
          border_radius="0px"
          :wrap_code="true"
          :language_selector="true"
          :languages="[['HTML', 'HTML']]"
          filled
        ></v-textarea>
      </v-card>
      <v-card style="flex: 1; min-height: 100%; display: flex; flex-direction: column" class="shadow-small mr-2">
        <div class="title px-6 py-5">Preview</div>
        <v-divider></v-divider>
        <div v-html="htmlEmail"
             style="flex: 1"
             class="pa-3"

        ></div>
      </v-card>
    </div>


  </div>
</template>

<script>
// import CodeEditor from 'simple-code-editor';
import PulseLoader from "../../../components/VLoader";

export default {
  name: "EmailPage",
  components: {
    // CodeEditor,
    PulseLoader
  },
  data() {
    return {
      subjectEmail: "",
      namesEmail: "",
      toEmail: "",
      loading: false,
      sent: false,
      htmlEmail: `
<div>
    <p>
    Bona tarda {{name}},
    <br>

    <br>
    Atentament,
    <br>
    Equip examenselectivitat
    </p>

</div>
      `
    }
  },
  methods: {
    async sendEmail() {
      try {
        this.loading = true;
        await this.axios.post("/html-email", {
          subject: this.subjectEmail,
          html: this.htmlEmail,
          to: this.toEmail,
          names: this.namesEmail
        }, {headers: {Authorization: this.$root.token.value}})
        this.sent = true;
        setTimeout(() => this.sent = false, 3000)
      } finally {
        this.loading = false;
      }

    }
  }
}
</script>

<style scoped>
.custom-tooltip {
  opacity: 1 !important;
}

.code >>> .v-input__control {
  height: 100%;
}
</style>
